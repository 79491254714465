<template>
  <Container :title="$t('myInvestmentsPage.myInvestments')" class="my-investments">
    <v-card elevation="2">
      <v-card-text class="pa-0 pa-sm-1">
        <v-container>
          <v-row>
            <v-col cols="12" class="px-3 px-sm-2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <RangeSelectorSlider v-model="investor_interest_range" min="1" max="20" step="0.1" :tooltip="$t('myInvestmentsPage.investorInterestPercentDescription')" :title="$t('myInvestmentsPage.investorInterestPercent')"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <RangeSelectorSlider v-model="period_range" min="0" max="120" :tooltip="$t('myInvestmentsPage.remainingLoanTermDescription')" :title="$t('myInvestmentsPage.remainingLoanTerm')"/>
                  </v-col>
                </v-row>
                <v-container class="buttonDividerContainer" :class="{ 'my-4': showAdvancedSettings }">
                  <v-row align="center">
                    <v-divider/>
                    <v-btn
                      class="ma-2"
                      color="primary lighten-3"
                      outlined
                      @click="showAdvancedSettings = !showAdvancedSettings"
                    >
                      <v-icon v-if="showAdvancedSettings" left>mdi-chevron-up</v-icon>
                      <v-icon v-else left>mdi-chevron-down</v-icon>
                      {{ $t('myInvestmentsPage.additionalOptions') }}
                    </v-btn>
                    <v-divider/>
                  </v-row>
                </v-container>
                <v-expand-transition>
                  <section v-show="showAdvancedSettings">
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0">
                        <BaseMultiselect
                          v-model="filters.loan__country"
                          filled
                          :items="countries.map(c => c.code)"
                          :label="$t('myInvestmentsPage.loanIssueCountry')"
                          :loading="loadingFilterHints"
                          multi-select-data-type="country"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0 pt-0 pt-md-3">
                        <BaseMultiselect
                          v-model="filters.loan__loan_type"
                          filled
                          :items="loanTypes"
                          item-text="name"
                          item-value="code"
                          :label="$t('myInvestmentsPage.loanTypePurpose')"
                          :loading="loadingFilterHints"
                          multi-select-data-type="loanType"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <BaseMultiselect
                          v-model="filters.loan__loan_originator__common_name"
                          filled
                          :items="loanOriginators"
                          :label="$t('myInvestmentsPage.loanOriginator')"
                          :loading="loadingFilterHints"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pt-0 pb-7">
                        <RangeSelectorSlider v-model="loan_amount_range" min="300" max="7000" :tooltip="$t('myInvestmentsPage.loanAmountDescription')" :title="$t('myInvestmentsPage.loanAmount')"/>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-7 pb-md-0">
                        <RangeSelectorSlider v-model="amount_range" min="0" max="4000" :tooltip="$t('myInvestmentsPage.investedAmountDescription')" :title="$t('myInvestmentsPage.investmentAmount')"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pt-0 pb-7 pb-md-4">
                        <RangeSelectorSlider v-model="aprc_range" min="1" max="3000" step="1" :tooltip="$t('myInvestmentsPage.borrowerAprcPercentDescription')" :title="$t('myInvestmentsPage.borrowerAprcPercent')"/>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-4 pb-md-0">
                        <RangeSelectorSlider v-model="borrower_interest_range" min="1" max="1000" step="1" :tooltip="$t('myInvestmentsPage.borrowerInterestRatePercentDescription')" :title="$t('myInvestmentsPage.borrowerInterestRatePercent')"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-row>
                          <v-col cols="12" md="6" class="pb-0">
                            <datepicker
                              v-model="filters.loan__value_date__gte"
                              :label="$t('myInvestmentsPage.loanIssueDateFrom')"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pt-0 pt-md-3">
                            <datepicker
                              v-model="filters.loan__value_date__lte"
                              :label="$t('myInvestmentsPage.loanIssueDateTo')"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-row>
                          <v-col cols="12" md="6" class="pb-0 pt-0 pt-md-3">
                            <datepicker
                              v-model="filters.created_at__gte"
                              :label="$t('myInvestmentsPage.dateOfPurchaseFrom')"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pt-0 pt-md-3">
                            <datepicker
                              v-model="filters.created_at__lte"
                              :label="$t('myInvestmentsPage.dateOfPurchaseTo')"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <v-select
                          v-model="filters.loan__schedule_extendable"
                          filled
                          :items="[{'text': $t('myInvestmentsPage.yes'), 'value': true}, {'text': $t('myInvestmentsPage.no'), 'value': false}, ]"
                          :label="$t('loanDetails.isScheduleExtendable')"
                          clearable
                        >
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span v-text="$t('loanDetails.scheduleExtensionDescription')"/>
                          </v-tooltip>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-select
                          v-model="filters.loan__buyback_guarantee"
                          filled
                          :items="[{'text': $t('myInvestmentsPage.yes'), 'value': true}, {'text': $t('myInvestmentsPage.no'), 'value': false}, ]"
                          :label="$t('myInvestmentsPage.buybackObligation')"
                          clearable
                        >
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" color="primary"
                                      dark v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span v-text="$t('myInvestmentsPage.buybackObligationDescription')"/>
                          </v-tooltip>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0 pt-0">
                        <v-select
                          v-model="filters.investment_type"
                          filled
                          :items="investmentTypes"
                          item-text="name"
                          item-value="code"
                          :label="$t('myInvestmentsPage.investmentType')"
                          :loading="loadingFilterHints"
                          clearable
                          multiple
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0 pt-0">
                        <v-select
                          v-model="filters.investment_completeness"
                          filled
                          :items="completenessStatus"
                          item-text="name"
                          item-value="code"
                          :label="$t('myInvestmentsPage.completenessStatus')"
                          clearable
                          multiple
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0 pt-0">
                        <!-- Search by loan ID goes here -->
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0 pt-0 pt-md-3"/>
                    </v-row>
                  </section>
                </v-expand-transition>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn ref="filter" large dark @click="submit()" v-text="$t('common.filter')"/>
                    <v-btn large text @click="clear()" v-text="$t('common.clear')"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card class="mt-6">
      <v-card-text>
        <InvestmentList ref="investmentList" :items="items" :loading="loading" :count="count" :page.sync="page" :size.sync="size" :show-sell="true" @investment="getItems"/>
      </v-card-text>
    </v-card>
  </Container>
</template>

<script>
import Vue from 'vue'
import InvestmentList from '../components/InvestmentList.vue'
import RangeSelectorSlider from "../../common/components/RangeSelectorSlider"

export default Vue.extend({
  name: "MyInvestments",
  components: {
    InvestmentList,
    RangeSelectorSlider,
  },
  data() {
    return {
      valid: true,
      investor_interest_range: [1, 20],
      aprc_range: [1, 3000],
      borrower_interest_range: [1, 1000],
      loan_amount_range: [300, 7000],
      amount_range: [0, 4000],
      period_range: [0, 120],
      filters: {},
      filterHints: { country: [], loan_type: [], loan_originator: []},
      loading: false,
      loadingFilterHints: false,
      page: 1,
      size: 10,
      count: 0,
      items: [],
      showAdvancedSettings: false,
      abortController: undefined,
    }
  },
  computed: {
    countries() {
      return this.filterHints.country.map(code => {
        return { code, name: this.$t(`countries.${code}`) }
      })
    },
    loanTypes() {
      return this.filterHints.loan_type.map(code => {
        return { code, name: this.$t(`loanDetails.loanTypes.${code}`) }
      })
    },
    bidTypes() {
      return [
        { value: 'myInvestmentsPage.manual',          text: this.$t('myInvestmentsPage.manual')     },
        { value: 'myInvestmentsPage.autoinvest',      text: this.$t('myInvestmentsPage.autoinvest') },
      ]
    },
    statuses() {
      return [
        { value: 'current',         text: this.$t('myInvestmentsPage.current') },
        { value: 'late',            text: this.$t('myInvestmentsPage.late')    },
        { value: 'defaulted',       text: this.$t('myInvestmentsPage.default') },
      ]
    },
    accountId() {
      return this.$store.state.account.id
    },
    investmentTypes() {
      // Same as
      // return [
      //   { code: 'manual',     name: this.$t('myInvestmentsPage.manual')     },
      //   { code: 'autoinvest', name: this.$t('myInvestmentsPage.autoinvest') },
      // ]
      return ['manual', 'autoinvest'].map(code => {
        return { code, name: this.$t(`myInvestmentsPage.${code}`) }
      })
    },
    completenessStatus() {
      return ['complete', 'incomplete'].map(code => {
        return { code, name: this.$t(`myInvestmentsPage.completenessStatuses.${code}`) }
      })
    },
    loanOriginators() {
      return this.filterHints.loan_originator.map(lo => lo.common_name)
    },
  },
  watch: {
    page() { this.getItems(); this.$vuetify.goTo(this.$refs.investmentList) },
    size() { this.getItems() },
    accountId() {
      this.getItems()
    },
    investor_interest_range(value) {
      this.$set(this.filters, 'investor_interest__gte', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'investor_interest__lte', (value[1] / 100).toFixed(4))
    },
    aprc_range(value) {
      this.$set(this.filters, 'loan__aprc__gte', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'loan__aprc__lte', (value[1] / 100).toFixed(4))
    },
    borrower_interest_range(value) {
      this.$set(this.filters, 'loan__borrower_interest__gte', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'loan__borrower_interest__lte', (value[1] / 100).toFixed(4))
    },
    amount_range(value) {
      this.$set(this.filters, 'amount__gte', value[0])
      this.$set(this.filters, 'amount__lte', value[1])
    },
    loan_amount_range(value) {
      this.$set(this.filters, 'loan__amount__gte', value[0])
      this.$set(this.filters, 'loan__amount__lte', value[1])
    },
    period_range(value) {
      if (value.length) {
        this.$set(this.filters, 'loan__maturity_date__gte', value[0])
        this.$set(this.filters, 'loan__maturity_date__lte', value[1])
      }
    },
    filters: {
      deep: true,
      handler() {
        if (this.page !== 1) {
          this.page = 1
        } else {
          this.getItems()
        }
      },
    },
  },
  mounted() {
    this.getFilterHints()
    this.getItems()
  },
  methods: {
    getFilterHints() {
      this.loadingFilterHints = true
      this.$http
        .get(`/loans/filter_hints/`)
        .then((result) => {
          this.filterHints = result.data
          this.loadingFilterHints = false
        })
        .catch(() => {
          this.loadingFilterHints = false
        })
    },
    clear() {
      this.$set(this.investor_interest_range, 0, '1')
      this.$set(this.investor_interest_range, 1, '20')
      this.$set(this.period_range, 0, '0')
      this.$set(this.period_range, 1, '120')
      this.$set(this.aprc_range, 0, '1')
      this.$set(this.aprc_range, 1, '3000')
      this.$set(this.borrower_interest_range, 0, '1')
      this.$set(this.borrower_interest_range, 1, '1000')
      this.$set(this.loan_amount_range, 0, '300')
      this.$set(this.loan_amount_range, 1, '7000')
      this.$set(this.amount_range, 0, '0')
      this.$set(this.amount_range, 1, '4000')
      this.filters = {}
    },
    submit() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.$refs.form.validate()) {
            this.page = 1
            this.getItems()
          }
        })
      })
    },
    countMadePayments(scheduled_payments) {
      const now = new Date()
      const items = scheduled_payments.filter(s => new Date(s.payment_date) > now).length
      if (items) {
        return ` (${items})`
      }
    },
    formatFilterDate(date) {
      if (!date) {
        return false
      }
      const month = new String(date.getMonth() + 1)
      const day = new String(date.getDate())
      return `${date.getFullYear()}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    getItems() {
      if (this.accountId) {
        const filters = { ...this.filters }
        // When converting querry parameters to payload parameters, backend accepts values as
        // stringified arrays. To keep payload cleaner, the missing parameters are set to null,
        // whitch would otherways be sent as `undefined`. Any remaining unformatted fields are then
        // deleted for the sake of cleanliness
        filters.loan_originator = filters.loan__loan_originator__common_name?.toString() || null
        filters.loan__loan_type = filters.loan__loan_type?.toString() || null
        filters.loan__country = filters.loan__country?.toString() || null
        filters.investment_type = filters.investment_type?.toString() || null
        filters.investment_completeness = filters.investment_completeness?.toString() || null
        delete filters.loan__loan_originator__common_name
        this.loading = true
        if (this.abortController !== undefined) { this.abortController.abort() }
        this.abortController = new AbortController()
        this.$http.get('/investments/', {
          signal: this.abortController.signal,
          params: {
            ...filters,
            page: this.page,
            size: this.size,
            investment_account: this.accountId,
          },
        }).then((investments) => {
          this.loading = false
          this.count = investments.data.count
          this.items = investments.data.results
        }).catch((e) => {
          if (e.code != "ERR_CANCELED") {
            // Catch also gets called on cancel, but we don't
            // want to stop the loading animation since we just
            // replaced previous request with a new one
            this.loading = false
          }
        })
      }
    },
  },
})
</script>

<style scoped>
.buttonDividerContainer {
  transition: margin 0.3s;
}
</style>
